import { User } from 'configcat-common';
import { useFeatureFlag } from 'configcat-react';

interface useGlobalFlagProps {
  flag: string;
  defaultValue: any;
  identifier?: string;
  email?: string;
  customDetails?: { [key: string]: string };
}
// Similar to useFlag but does not include the signed in user's details, this way
// it can be used anywhere without being wrapped with ApolloProvider
export const useGlobalFlag = ({
  flag,
  defaultValue,
  identifier = '',
  email = '',
  customDetails = {},
}: useGlobalFlagProps) => {
  const user: User = {
    identifier: identifier || '',
    email: email || '',
    custom: customDetails,
  };
  return useFeatureFlag(flag, defaultValue, user);
};
