import { isApolloError } from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import jwt_decode from 'jwt-decode';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { useRequestLoginLink } from 'src/apollo/mutations';
import { useAlert } from 'src/hooks/useAlert';
import { useGlobalFlag } from 'src/hooks/useGlobalFlag';

import KantanLogo from '../../components/KantanLogo';

export const LoginScreen = () => {
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const alert = useAlert();
  const { value: loginAllowed } = useGlobalFlag({
    flag: 'loginAllowed',
    defaultValue: true,
    email,
  });
  const [requestLoginLink] = useRequestLoginLink({
    variables: { emailAddress: email.trim() },
    context: {
      requiresAuth: false,
    },
  });
  const router = useRouter();
  const submitEmail = async () => {
    if (!loginAllowed) {
      alert({
        type: 'error',
        message: 'Login is not permitted.',
      });
      return;
    }
    try {
      const response = await requestLoginLink();
      const emailOrToken = response?.data?.requestLoginLink.emailAddress;
      if (emailOrToken && emailOrToken !== email.trim()) {
        try {
          jwt_decode(emailOrToken, { header: true });
          router.push({ pathname: '/auth', query: { token: emailOrToken } });
        } catch (err: any) {
          alert({
            type: 'error',
            message: 'Looks like something went wrong. Please try again later',
          });
        }
      }
      setSubmitted(true);
      alert({ type: 'success', message: 'Email sent! Check your inbox' });
    } catch (err: any) {
      alert({
        type: 'error',
        message: 'Looks like something went wrong. Please try again later',
      });
      if (isApolloError(err)) {
        //TODO: Navigate to Registration page
        return;
      }
      captureException(err);
    }
  };

  return (
    <>
      <Head>
        <title>Kantan Login</title>
        <meta name="description" content="Kantan browser login." />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className="flex flex-col items-center justify-center min-h-screen min-w-full bg-background text-typography">
        <div className="my-5">
          <KantanLogo.Small width="100" height="100" color="#0B4ED5" />
        </div>
        {!submitted ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitEmail();
            }}
            className="p-4"
          >
            <p
              data-testid="signin-text-header"
              className="text-center text-lg font-bold"
            >
              Get started with Kantan on your Desktop
            </p>
            <p data-testid="signin-text" className="text-center text-lg mb-10">
              Enter the email address you&apos;ll use to log in to Kantan
            </p>
            <div className="my-5 bg-white p-4 text-typography-secondary rounded-lg">
              <label htmlFor="email1" className="text-xs uppercase">
                Your email<sup className="text-typography-error">*</sup>
              </label>
              <input
                id="email1"
                type="email"
                placeholder="Enter your email address"
                required={true}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={submitted}
                data-testid="login-input"
                className="w-full border-none p-0 shadow-none focus:shadow-none focus:ring-transparent"
              />
            </div>
            <button
              type="submit"
              data-testid="login-magic-button"
              disabled={submitted}
              className="w-full bg-cta-action rounded-lg text-white p-2 text-md hover:bg-cta-actionHover py-2.5 transition font-semibold"
            >
              Get Login Link
            </button>
          </form>
        ) : (
          <div className="p-4 max-w-[29rem] w-full text-center">
            <p
              data-testid="submitted-text-header"
              className="text-center text-lg font-bold"
            >
              Check your email!
            </p>
            <p data-testid="submitted-text" className=" text-lg">
              We&apos;ve sent your login link to:
            </p>
            <p className="text-lg font-medium">{email}</p>
            <p className="py-10">You may now close this tab</p>
            <p> OR </p>
            <p
              className="mt-2 text-cta-action cursor-pointer font-medium"
              onClick={() => {
                setSubmitted(false);
              }}
            >
              Something wrong? Re-enter your email address
            </p>
          </div>
        )}
      </main>
    </>
  );
};
