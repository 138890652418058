import { LoginScreen } from 'src/screens/login/LoginScreen';
import { NextPageWithLayout } from 'src/utils';

import { DefaultLayout } from '../layouts/DefaultLayout';

const Login: NextPageWithLayout = () => {
  return <LoginScreen />;
};

Login.getLayout = (page: any) => <DefaultLayout>{page}</DefaultLayout>;

export default Login;
